var init = require('init');

init.then(function (init) {
	require('../sass/normalize.scss');
	require('@material/layout-grid/mdc-layout-grid.scss');
	require('@os/typography/typography.scss');
	require('@os/mdc/button.scss');
	require('@os/list/list.scss');
	require('@os/mdc/layout-grid.scss');
	require('@os/helper/helper.scss');

	require('../sass/home-header/home-header.scss');
	require('../sass/home-transparency-guarantee/home-transparency-guarantee.scss');
	require('../sass/pro-cta-home/pro-cta-home.scss');
	require('../sass/home-right-choice/home-right-choice.scss');
	require('../sass/home-search-clients/home-search-clients.scss');
	require('../sass/home-ifop-partner/home-ifop-partner.scss');
	require('../sass/home-news-articles/home-news-articles.scss');
	require('../sass/news-card/news-card.scss');
	require('../sass/bottom-subscribe-newsletter/bottom-subscribe-newsletter.scss');

	require('slick-carousel/slick/slick.scss');

	var templateTest = require('../view/index.hbs');

	var counter_review_start = null;
	var counter_review_delay_min = 5;
	var counter_review_delay_max = 15;
	var configCounter;

	define(['jquery', 'js-cookie', 'i18next', '@os/header', '@os/footer', 'moment', 'slick-carousel', '@os/bottom-subscribe-newsletter', '@os/message-bar'], function ($, jsCookie, i18next, header, footer, moment, slick, bottomSubscribeNewsletter, messageBar) {
		$(function () {
			$(document).on('mouseover mouseout', '.news-card .article-mouseover', function (e) {
				$(this).next('.mdc-card__primary-action').find('.news-card__article-description').slideToggle();
			});

			promises = [];
			promises.push(new Promise(function (resolve, reject) {
				$.ajax({
					url: '/api/news/load_data',
					type: 'POST',
					dataType: 'json',
					data: {
						limit: 4
					}
				}).done(function (data) {
					resolve(data);
				}).fail(function (xhr, error) {
					reject(error);
				});
			}));

			promises.push(new Promise(function (resolve, reject) {
				$.ajax({
					type: 'GET',
					url: '/api/statistic/general',
					dataType: 'json'
				}).done(function (data) {
					resolve(data);
				}).fail(function (xhr, error) {
					reject(error);
				});
			}));
			Promise.all(promises).then(function (result) {
				moment.locale('fr');
				var data = {
					news_article: result[0].news_article,
					survey_total: result[1].survey_total,
					counter_review_daily: result[1].counter_review_daily,
					company_total: result[1].company_total,
					locale: jsCookie.get('locale')
				};

				$.each(data.news_article, function (key, value) {
					value.published_date = moment.utc(value.published_date).format("DD MMM");
				})
				$('body').append(templateTest(data));
				header.init();
				footer.init();
				messageBar.init();
				bottomSubscribeNewsletter.init();
				configCounter = data;
				var digits = configCounter['survey_total'].toString().split('');
				digits.reverse();
				for (var number = 0; number < digits.length; number++) {
					$('#counter_box .number_' + number).html(digits[number]);
				}

				// mobile news articles carousel
				$('.home-news-slider').slick({
					mobileFirst: true,
					slidesToShow: 2,
					arrows: false,
					variableWidth: true,
					responsive: [{
						breakpoint: 600,
						settings: "unslick"
					}]
				})
				// news article hover layer
				$articles = $('.article-mouseover');
				$.each($articles, function (key, value) {
					$(value).css({
						position: "absolute",
						left: 0,
						top: 0,
						width: value.parentNode.offsetWidth,
						height: value.parentNode.offsetHeight,
						"background-color": 'transparent',
						"z-index": 999,
						"opacity": 0,
					});
				});
			});
		});
	});
});